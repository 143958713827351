<template>
  <div class="focus:outline-[0px]" ref="refocus" tabindex="-1">
    <Header />
    <slot />
    <Footer />
    <GetQuoteButton />
    <EIModal />
  </div>
</template>

<script setup>
import { isbot } from 'isbot'

useHead({
  script: [
    {
      src: 'https://assets.calendly.com/assets/external/widget.js',
      async: true
    }
  ]
})

const route = useRoute()
const userStore = useUserStore()
const refocus = ref()
const currentUrl = ref('')
const { canTrk } = storeToRefs(userStore)

const pipedriveContactAdded = ref(false)

const generateRandomString = () => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let charactersLength = characters.length
  for (var i = 0; i < 30; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// generate an anonymous ID for our user
if (!userStore.userId) {
  const userId = generateRandomString()
  userStore.storeUserId(userId)
}

const getSourceAndMedium = referrer => {
  const sources = [
    { domain: 'linkedin.', source: 'linkedin', medium: 'social' },
    { domain: 'google.', source: 'google', medium: 'organic' },
    { domain: 'bing.', source: 'bing', medium: 'organic' },
    { domain: 'yahoo.', source: 'yahoo', medium: 'organic' },
    { domain: 'duckduckgo.', source: 'duckduckgo', medium: 'organic' },
    { domain: 'yandex.', source: 'yandex', medium: 'organic' },
    { domain: 'reddit.', source: 'reddit', medium: 'social' },
    { domain: 'quora.', source: 'quora', medium: 'social' },
    { domain: 'facebook.', source: 'facebook', medium: 'social' },
    { domain: 'twitter.', source: 'twitter', medium: 'social' },
    { domain: 'perplexity.ai', source: 'perplexity', medium: 'organic' },
    { domain: 'msn.com', source: 'msn', medium: 'organic' },
    { domain: 'ecosia.org', source: 'ecosia', medium: 'organic' }
  ]

  if (!referrer) {
    return { source: 'direct', medium: 'none' }
  }

  const matchedSource = sources.find(item => referrer.includes(item.domain));
  if (matchedSource) {
    return { source: matchedSource.source, medium: matchedSource.medium };
  }

  return { source: referrer, medium: 'referral' };
}


const checkRouteParams = async () => {

  // stop the form firing twice
  if (pipedriveContactAdded.value) return

  const userEmail = route?.query?.invitee_email || null
  const userName = route?.query?.invitee_full_name || null

  if (!userEmail || !userName || userStore.sentToPipedrive) {
    return
  }

  const data = {
    name: userName,
    email: userEmail,
    webformId: '61', // form ID - Book demo form ID
    mixpSource: userStore.mixpSource,
    mixpMedium: userStore.mixpMedium,
    mixpCampaign: userStore.mixpCampaign,
    mixpLandingPage: userStore.mixpLandingPage
  }

  pipedriveContactAdded.value = true

  try {
    const addUserRes = await $fetch('/api/pipedriveBookDemo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
        
    if (!addUserRes.ok) {
      return
    }

  } catch(e) {
    console.log(e)
  }

  if (process.client && canTrk.value) {

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'elker_conversion'
      })
      window.dataLayer.push({
        event: 'demo_booked'
      })
    }

    userStore.sendUserToPipedrive()
    
  }
}

const initView = async () => {
  if (process.client && canTrk.value) {
    checkRouteParams()
  }
}


const trk = async () => {
  if (!canTrk.value) return

  checkRouteParams()

  // tracking
  let mixpSource = route?.query?.utm_source || null
  let mixpMedium = route?.query?.utm_medium || null
  let mixpCampaign = route?.query?.utm_campaign || null
  let mixpLandingPage = route?.path || null

  if (mixpSource === 'linkedin.com') mixpSource = 'linkedin'

  if (mixpMedium === 'cpc') mixpMedium = 'paid'

  if (mixpSource && mixpMedium && !userStore.mixpMedium) {
    userStore.updateMixpUtm(mixpSource, mixpLandingPage, mixpMedium, mixpCampaign)
  } else if (mixpSource && !userStore.mixpSource) {
    userStore.updateMixpUtm(mixpSource, mixpLandingPage, mixpMedium, mixpCampaign)
  }
  
  if (!userStore.mixpSource) {
    mixpSource = document?.referrer || null
    const sourceMedium = getSourceAndMedium(mixpSource)
    userStore.updateMixpUtm(sourceMedium.source, mixpLandingPage, sourceMedium.medium)
  }

  // additional bot check if user/bot keeps clicking on our ads
  if (mixpMedium === 'paid') {
    userStore.addUtmVisit(Date.now(), mixpSource)  

    if (userStore.checkDifferentUtms() > 2 || userStore.countUtmVisits() > 4) {
      userStore.doNotTrk()
    }
  }

  initView()

}

const setTrkStatus = () => {
  return new Promise((resolve) => {

    const userAgent = navigator.userAgent

    if (window?.location?.host.includes('localhost:300')) {
      userStore.doNotTrk()
      resolve()
      return
    }
  
    if (isbot(userAgent)) {
      userStore.doNotTrk()
      userStore.isBot()
      resolve()
      return
    }

    const userAgentLower = userAgent.toLowerCase()
    
    if (userAgentLower.includes('safari') && userAgentLower.includes('linux')) {
      userStore.doNotTrk()
      userStore.isBot()
      resolve()
      return
    }

    resolve()
    
  })
}


watch(
  () => route.path, 
  () => {
    currentUrl.value = route.fullPath
    refocus.value?.focus()
  }
)

watch(
  () => route.query, 
  () => {
    if (canTrk.value) {
      checkRouteParams()
    }
  }
)

onMounted(async () => {
  refocus.value?.focus()
  await setTrkStatus()
  trk()
})

</script>